// extracted by mini-css-extract-plugin
export var bgTxt = "solution3-0-module--bgTxt--UAeEj";
export var body = "solution3-0-module--body--tpzjv";
export var content = "solution3-0-module--content--hCR73";
export var genealogy = "solution3-0-module--genealogy--A0BNw";
export var head = "solution3-0-module--head--UERbM";
export var img = "solution3-0-module--img--+y4-O";
export var inner = "solution3-0-module--inner--4QPc4";
export var item = "solution3-0-module--item--AjLaI";
export var label = "solution3-0-module--label--cSwPr";
export var lead = "solution3-0-module--lead--yAhlj";
export var list = "solution3-0-module--list--JW77j";
export var pillars = "solution3-0-module--pillars--MUBg8";
export var service = "solution3-0-module--service--Q8KdK";
export var subtitle = "solution3-0-module--subtitle--YQ8eG";
export var top = "solution3-0-module--top---sAB7";