import * as React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Information from "../components/information"

import IMG_04 from "../images/service-04.png"
import IMG_05 from "../images/service-05.png"
import IMG_06 from "../images/service-06.png"

import * as styles from "../styles/solution3.0.module.scss"

const Solution2 = () => {
  return (
    <Layout>
      <Seo title="サービス(WEB3.0)" description="このページは「サービス(WEB3.0)」に関するページです。" />
      <section className="cPageHeader">
        <div className="inner cContainer">
          <div className="content">
            <h2>
              <span className="alphabet">SOLUTION3.0</span><br />
              WEB3.0対応サービス
            </h2>
          </div>
        </div>
      </section>

      <section className={styles.service}>
        <div className={`${styles.inner} cContainer`}>
          <div className={styles.content}>
            <div className={styles.genealogy}>
              <div className={styles.top}>
                <p className={`${styles.bgTxt} alphabet`}>solution</p>
                <div className={`${styles.lead} alphabet`}>WEB3.0</div><br/>
                ブロックチェーン事業
              </div>
              <div className={styles.list}>
                <div className={styles.item}>
                  <div className={styles.img}>
                    <img src={IMG_04}></img>
                    <p className={styles.label}>プロダクト</p>
                  </div>
                  <p>
                    仮想通貨開発<br/>
                    マイニングマシーン開発<br/>
                    ステーキング開発
                  </p>
                </div>
                <div className={styles.item}>
                  <div className={styles.img}>
                    <img src={IMG_05}></img>
                    <p className={styles.label}>プラットフォーム</p>
                  </div>
                  <p>
                    NFTマーケットプレイス開発<br/>
                    DEX・DeFi開発<br/>
                    ウォレット開発
                  </p>
                </div>
                <div className={styles.item}>
                  <div className={styles.img}>
                    <img src={IMG_06}></img>
                    <p className={styles.label}>その他</p>
                  </div>
                  <p>
                    スマートコントラクト調査<br/>
                    ブロックチェーンアドレススキャン開発
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.pillars}>
        <div className={`${styles.inner} cContainer`}>
          <div className={styles.content}>
            <div className={styles.head}>
              <div className={`${styles.subtitle} alphabet`}>pillars</div>
              <h2>サポートできる技術</h2>
            </div>
            <div className={styles.body}>
              <ul className={styles.pillars}>
                <li>オリジナルチェーン開発</li>
                <li>プライベートチェーン開発</li>
                <li>EVMチェーン開発</li>
                <li>マイニングマシーン開発</li>
                <li>ステーキング開発</li>
                <li>DEX開発</li>
                <li>Lending開発</li>
                <li>DeFi開発</li>
                <li>ウォレット開発</li>
                <li>ミントシステム開発</li>
                <li>ブロックチェーンアドレススキャン開発</li>
                <li>NFT発行</li>
                <li>トークン発行</li>
                <li>スマートコントラクト調査</li>
                <li style={{ visibility: 'hidden' }}></li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Information />
      
    </Layout>
  )
}

export default Solution2